import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import ContactFormHire from '../contact/ContactFormHire';
import { BiUserCheck } from "react-icons/bi";

const SplitSix = () => {
    return (
            <div className="rn-splite-style">
                <div className="split-wrapper">
                        {/* <div className="row no-gutters radius-10 align-items-center"> */}
                        <div className="row no-gutters radius-10">
                            <div className="col-lg-12 col-xl-6 col-12" style={{padding: 100, paddingTop: 20}}>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <ContactFormHire/>
                                </ScrollAnimation>
                                {/* <div className="thumbnail image-left-content">
                                    <img src="./images/split/split-01.jpg" alt="split Images" />
                                </div> */}
                            </div>
                            <div className="col-lg-12 col-xl-6 col-12">
                                <div className="split-inner">
                                    <div className="pt--10" style={{fontSize: 60, color: '#5b849e'}}><BiUserCheck/></div>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title pt--0">Hire Developers as per your Project Requirements</h4>
                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <div className="description pt--20">One week free trial by scheduling an Interview. Our team will reach out to you shortly after you submit the form.</div>
                                    </ScrollAnimation>
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                    <div className="description pt--10">Our team will reach out to you shortly after you submit the form.</div>
                                    </ScrollAnimation> */}

                                    
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <ul className="split-list">
                                            <li>- Doug DeMarco, Design Prototyping Manager</li>
                                            <li>- 108 million paying subscribers</li>
                                            <li>- Over 1.7 billion hours of music played monthly</li>
                                            <li>- 4,000+ employees working across 16 offices</li>
                                        </ul>
                                    </ScrollAnimation> */}
                                    {/* <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <div className="view-more-button mt--35">
                                            <a className="btn-default" href="/contact">Contact With Us</a>
                                        </div>
                                    </ScrollAnimation> */}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default SplitSix
