import React from 'react';
import { FiActivity, FiCast, FiMap, FiClock } from "react-icons/fi";
// import {Link} from "react-router-dom";
import {Link} from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <FiClock />,
        title: 'Full Time',
        hours: '8 hrs/day',
        days: 'Full Month'
    },
    {
        icon: <FiClock />,
        title: 'Part Time',
        hours: "4 hrs/day",
        days: 'Full Month'
    },
    {
        icon: <FiClock />,
        title: 'Hourly',
        hours: 'Flexible',
        days: 'Flexible'
    },
]

const ServiceSix = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper pt--40" style={{display: 'flex',justifyContent: 'center'}}>
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`} style={{minHeight: 312}}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <div>Hours Per Day</div>
                                <div style={{marginTop: 5, padding: 5, background: '#5b849e', color: '#fff'}}>{val.hours}</div>
                                <div style={{paddingTop: 20}}>Days Per Month</div>
                                <div style={{marginTop: 5, padding: 5, background: '#5b849e', color: '#fff'}}>{val.days}</div>
                                {/* <p className="b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p> */}
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceSix;