import React , {useState} from 'react';
import emailjs from 'emailjs-com';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. We will contact you soon.</p>
    )
}

function ContactFormHire({props , formStyle}) {
    const [ result, showresult ] = useState(false);
    const [ selected, setSelected ] = useState('Select Developer Service');
    const options = [
        'RDK / RDKB Developer', 'openSync Developer', 'openWrt Developer', 'CPE Containers Developer', 'EasyMesh Developer', 'QCOM WiFi SON Developer', 'TR181 Developer', 'CPE Telemetry Developer', 'BLE Developer', 'WiFi Analytics Developer', 'Others'
    ];
    // const defaultOption = options[0];
    const defaultOption = selected;

    const sendEmail = (e) => {
        e.preventDefault();
        // emailjs
        // .sendForm(
        //     'service_p4x3hv8', 
        //     'template_jgfr42f', 
        //     e.target, 
        //     'user_jrfTH2e0Ely35ZCVFdT9S'
        // )
        // .then((result) => {
        //     console.log(result.text);
        //     }, 
        //     (error) => {
        //         console.log(error.text);
        //     }
        // );
        // e.target.reset();
        // showresult(true);

        let name = e.target.closest("form")[0].value;
        let email = e.target.closest("form")[1].value;
        let phone = e.target.closest("form")[2].value;
        let subject = e.target.closest("form")[3].value;
        let message = e.target.closest("form")[4].value;

        const templateParams = {
            from_name: name,
            reply_to: email,
            phone: phone,
            subject: "Hire Developer Service - "+subject,
            message: message
        };
        console.log('templateParams: ',templateParams);
        emailjs.send(
            'service_blovxp9', 
            'template_uqvhee2', 
            templateParams, 
            '39f7JXvhN-WHWHe8K'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    const _onSelect = (option) => {
        setSelected(option.label);
    };

    return (
        <form className={`${formStyle}`} action="" onSubmit={sendEmail}>
            <div className="form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Your Name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                name="email"
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number"
                required
                />
            </div>


            {/* <div className="form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                required
                />
            </div> */}
            <div className="form-group">
                <input 
                    type="hidden"
                    name="service"
                    placeholder="Phone Number"
                    value={defaultOption}
                />
            </div>
            <Dropdown className="drop-down-custom" options={options} onChange={_onSelect} value={defaultOption} placeholder="Select an option" />

            <div className="form-group">
                <textarea 
                name="message"
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>
            
            {/* <Dropdown />; */}
            <div className="form-group">
                <button className="btn-default btn-large" style={{background: '#5b849e'}}>Schedule Interview</button>
            </div> 

            <div className="form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactFormHire;
