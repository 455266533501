import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import ScrollAnimation from "react-animate-on-scroll";
import { FiX , FiArrowRight, FiCheck} from "react-icons/fi";

const Data = [
    {
        countNum : 199,
        countTitle: 'Happy Clients.',
    },
    {
        countNum : 575,
        countTitle: 'Employees',
    },
    {
        countNum : 69,
        countTitle: 'Useful Programs',
    },
    {
        countNum : 500,
        countTitle: 'Useful Programs',
    },
];
const SlipFour = () => {
    return (
        <div className="rn-splite-style bg-color-blackest">
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            {/* <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h4 className="title" style={{fontSize: 40}}>Hire Dedicated <br/>Developers </h4>
                                <div style={{borderBottom: "3px solid #000", width: '10vw'}}></div>

                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description pt--50" >Being a leading enterprise Development Company, we help you set up and manage remote dedicated development teams for you. INFERNET provides experienced developers, specialists, IT programmers, and experts for hire to inundate your project with brave ideas. Hire dedicated developers from INFERNET and break the mold.</p>
                            </ScrollAnimation> */}

                            <div className="inner text-left" style={{flex: 4}}>
                                    {/* <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">Powering millions of connected Home WiFi.</span></h3>
                                    </div> */}
                                    <ScrollAnimation 
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}>
                                        <h4 className="title" style={{fontSize: 40, lineHeight: 1}}>Hire Dedicated <br/>Developers </h4>
                                        <div style={{borderBottom: "3px solid #000", width: '10vw'}}></div>

                                    </ScrollAnimation>
                                    <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="pt--40" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                            <span className="icon-custom-1" style={{color: '#fff', background: '#5b849f'}}><FiCheck /></span> 
                                            <div className="description" style={{color: '#000', fontWeight: 'normal'}} >Get top-notch agile teams to help you achieve your vision on-demand.</div>
                                        </div>
                                    </ScrollAnimation> 
                                    <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="pt--20" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                            <span className="icon-custom-1" style={{color: '#fff', background: '#5b849f'}}><FiCheck /></span> 
                                            <div className="description" style={{color: '#000', fontWeight: 'normal'}} >Personalized Hiring Models to suit your requirements.</div>
                                        </div>
                                    </ScrollAnimation> 
                                    <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                        <div className="pt--20" style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                            <span className="icon-custom-1" style={{color: '#fff', background: '#5b849f'}}><FiCheck /></span> 
                                            <div className="description" style={{color: '#000', fontWeight: 'normal'}} >Connect with us to get experts working dedicatedly on your project, just as you need!</div>
                                        </div>
                                    </ScrollAnimation> 
                                    <div className="button-group mt--40 mt_sm--20" style={{position: 'relative'}}>
                                        <a className="btn-default" style={{background: '#5b849f'}} href="#contact-section">Contact Us <FiArrowRight/></a>
                                    </div>
                                </div>

                            {/* <div className="row">
                                {Data.map((data, index) => (
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                                        <div className="count-box counter-style-4 text-start">
                                            <TrackVisibility once>
                                                {({ isVisible }) => isVisible && 
                                                    <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                                            </TrackVisibility>
                                            <h5 className="counter-title">{data.countTitle}</h5>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                            {/* <div className="button-group mt--40 mt_sm--20" style={{position: 'relative'}}>
                                <a className="btn-default" style={{background: '#5b849f'}} target="_blank" href="#">Contact Us <FiArrowRight/></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img className="dev-image" src="/images/split/split-04.jpg" alt="split Images" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFour;