import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderDev from '../common/header/HeaderDev';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import Contact from '../elements/contact/ContactNew';
import ServiceFour from "../elements/service/ServiceFour";
import ServiceSix from "../elements/service/ServiceSix";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import BrandFive from "../elements/brand/BrandFive";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import SplitFive from "../elements/split/SlipFive";
import SplitSix from "../elements/split/SplitSix";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import Font from '../assets/css/font.css';
import { FiArrowRight, FiCheck } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import {Helmet} from "react-helmet";

const AboutUs = () => {
    return (
        <>
            <Helmet>
                <title>Router Architects - Hire a Developer for Wifi Digital Services</title>
                <meta name="description" content="Hire Developers in RDK/RDKB, opensync, openWrt, Easy Mesh, Qualcomm WiFi SON, TR181, CPE Telemetry, BLE, WiFi Analytics" />
                <link rel="icon" type="image/png" href="/images/logo/logo-favicon.png" sizes="16x16" />
            </Helmet>
            {/* <SEO title="hire-developer" /> */}
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderDev btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Elements Area  */}
                {/* <div className="rwt-split-area rn-section-gap"> */}
                <div className="rwt-split-area pt--40">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    // subtitle = "Split"
                                    // title = "Split Style Four"
                                    description = ""
                                />
                            </div>
                        </div>
                        <SplitFive />
                    </div>
                </div>
                {/* End Elements Area  */}

                {/* Start Service Area  */}
                 <div className="rn-service-area pt--100" style={{}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    // subtitle = "@SERVICES"
                                    // title = "Services provide for you."
                                    title = "Our Hiring Model"
                                    // description = "There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                                    />
                            </div>
                        </div>
                        
                        <ServiceSix
                            serviceStyle = "service__style--1 bg-color-blackest radius mt--25"
                            textAlign = "text-center"
                            />
                        
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Brand Area  */}
                
                <div className="rwt-brand-area pt--80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        // subtitle = "Our Awesome Client"
                                        title = "Developer Services"
                                        description = ""
                                    />
                                </ScrollAnimation>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <BrandFive brandStyle="brand-style-1" />
                            </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}
                {/* <Contact/> */}
                <div id="contact-section"></div>
                <div className="rwt-split-area pt--100">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-lg-12 mb--40">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    // subtitle = "Split"
                                    title = "Connect with us!"
                                    description = ""
                                />
                            </div>
                        </div>
                        <SplitSix />
                    </div>
                </div>


                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
